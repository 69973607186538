<template>
    <br>
    <div class="TF MT2">
        <el-divider v-if="$store.state.LandTo.split('QWER')[0] === 'BY2'" style="height: 1.2rem"> • {{ItemType}}:{{ItemName}} •</el-divider>
    </div>
    <div class="MLR4 LH150 TF MT2">
        <div class="XLR">
            <div>时间：{{CurrYY2.MeetTimeStr}}</div>
            <img src="../../../static/img/XMYY/InvitationSmall.jpg" @click="ShowInvitation=true" class="WH99">
        </div>
        <div v-if="ShowInvitation" class="FullScr1 TW BGBlack" :style="'z-index:'+ShowZindex">
            <div @click="ShowInvitation=false"
                 style='position:fixed;width:100%;height:100%;left:3vw;top:20vw;background:0 0 / 94% url(../../../UserUpload/static/img/XMYY/Invitation.jpg) no-repeat'>
                <div style="position:absolute;left: 24vw;top: 12vw">{{$store.state.UserName}}</div>
                <div style="position:absolute;left: 42vw;top: 55vw">{{CurrYY2.MeetTimeStr}}</div>
                <div style="position:absolute;left: 14vw;top: 63vw">...{{CurrYY2.MeetAddrStr.slice(-12)}}</div>
                <div style="position:absolute;left:10vw;top:73vw">{{CurrYY2.ItemStr.split(":")[1]}}</div>
                <div style="position:absolute;left:61vw;top:85vw">{{CurrYY2.zyUserName}}</div>
                <div class="TG" style="position:absolute;left:61vw;top:105vw;">邀请函出示完毕<br><span class="TW">点击这里...</span>
                </div>
            </div>
        </div>
        <div class="XLR YC">
            地点：{{CurrYY2.MeetAddrStr}}
            <div v-if="CurrYY2.Longi!==116.38"><img src="../../../static/img/SY/Map1.png" @click="ShowMap=true"
                                                    class="WH99 BD_Radius1"></div>
        </div>
        <div v-if="CurrYY2.fySitefee>0" class="XLR">
            <div>场地费：{{CurrYY2.fySitefee}}</div>
            <div>付费方式：{{CurrYY2.paymentWay}}</div>&nbsp;
        </div>
        <div v-if="CurrYY2.jdfy!==''">简短附言：{{CurrYY2.jdfy}}</div>
    </div>
    <FYMap v-if="ShowMap"
           :Msg="{Name:CurrYY2.ItemStr.split(':')[1]+'项目地址:',Addr:CurrYY2.MeetAddrStr,Longi:CurrYY2.Longi,Lati:CurrYY2.Lati}"
           OP="Look" @FromFYMap2="ShowMap=false"></FYMap>
    <div class="BottO">
        <el-divider>主约：{{CurrYY2.zyUserName}}</el-divider>
        <div class="MP4 XLR TF">
            <img :src="'/UserUpload/1/'+CurrYY2.UserLogo" @click="Show=1" class="AvatarWH"/>
            <div class="W80 ML2">
                <div class="XLR YC">
                    <div class="TC T3">
                        {{CurrYY2.sex}}<br>{{CurrYY2.age}}岁
                    </div>
                    <div class="TC T3 AlignC">
                        <div class="T2 TF BottGray">&nbsp;积分:&nbsp;</div>
                        {{CurrYY2.JF}}
                    </div>
                    <div class="MT2">
                        <ShowPVT :PVT="{P:CurrYY2.fyUserPic,V:CurrYY2.fyUserVideo,T:CurrYY2.fyUserText,Update:false}"></ShowPVT>
                    </div>
                </div>
                <div class="T2 XLR">
                    <div>人气信用:{{CurrYY2.PopularityCredit}}</div>
                    <div>综合才艺:{{CurrYY2.QN}}</div>
                </div>
            </div>
        </div>
        <div v-if="FirstXYBool===2" class="XR YC MR4 MT2">
            <div class="TC">线下活动情况：&nbsp;</div>
            <ShowPVT :PVT="{P:CurrYY2.Pic,V:CurrYY2.Video,T:CurrYY2.Text,Update:false}"></ShowPVT>
        </div>
    </div>
    <img v-if="Show===1" :src="'UserUpload/1/'+CurrYY2.UserLogo" @click="Show=-1" style="position:fixed;left:0;top:0;width: 100vw;height:auto"/>
    <div class="XLR YC MP4">
        <div>
            <span class="TG">响应：</span>
            <div v-if="FirstXYBool<2" class="BoxGray1 TG PA3 DispIB" @click="SelectI=0">{{CurrYY2.by ? CurrYY2.by.xy:''}}</div>
            <span v-else>{{CurrYY2.by ? CurrYY2.by.xy:''}}</span>
        </div>
        
        <div v-if="FirstXYBool===1" class="AN3" @click="FirstXY()">通知主约</div>
        <div v-if="FirstXYBool===2" class="T2">
            <div v-if="PJed" class="TF">已完成互评!</div>
            <div v-else>
                <div v-if="Changed1 && Changed2 && Changed3" class="AN3" @click="BYMsgSave()">通知主约</div>
                <p v-else class="TF">尚未互评！</p>
            </div>
        </div>
    </div>
    <div v-if="CurrYY2.by && FirstXYBool===1 && CurrYY2.by.xy==='暂拒'" class="MP4">
        暂拒理由 或 反馈建议 :<br>
        <input type="text" class="W80" v-model="CurrYY2.by.zg"/>
        <br>
    </div>
    <div v-if="FirstXYBool===2">
        <div v-if="CurrYY2.by.xy==='同意'">
            <div class="XR YC MR4 MT2">
                <div class="TC">线下活动情况：&nbsp;</div>
                <ShowUpdatePVT :PVTF="{P:CurrYY2.by.Pic,V:CurrYY2.by.Video,T:CurrYY2.by.Text,TextTitle:'线下活动情况'}"
                               @ShowUT="CallBack2"></ShowUpdatePVT>
            </div>
            <table style="background-color:#fff;">
                <thead>
                <tr class="TF">
                    <th>{{CurrYY2.ItemStr.charAt(0)==='A' ? '战况':''}}</th>
                    <th>履约情况</th>
                    <th>
                        <div v-if="CurrYY2.ItemStr.charAt(0)==='A'">战果</div>
                        <div v-else></div>
                    </th>
                    <th>互评</th>
                </tr>
                <tr>
                    <th class="W26">主约反馈:</th>
                    <th> {{CurrYY2.by.z_bylyqk==='初值'? '—':CurrYY2.by.z_bylyqk }}</th>
                    <th>
                        <div v-if="CurrYY2.ItemStr.charAt(0)==='A'">{{CurrYY2.by.z_zg==='初值'? '—': CurrYY2.by.z_zg}}</div>
                        <div v-else></div>
                    </th>
                    <th> {{CurrYY2.by.z_ts==='初值'? '—': CurrYY2.by.z_ts}}</th>
                </tr>
                <tr>
                    <th class="W26">被约观点:</th>
                    <th>
                        <div v-if="CurrYY2.by.xy === '同意' && !PJed" @click="SelectI=2" class="XYC">
                            <div v-if="CurrYY2.by.b_zylyqk==='初值'" class="AN ANGray">点这里</div>
                            <div v-else class="AN ANGreen ">{{CurrYY2.by.b_zylyqk}}</div>
                        </div>
                        <div v-else class="AN ANGray ">
                            <div v-if="PJed ">{{CurrYY2.by.b_zylyqk==='初值'? '—':CurrYY2.by.b_zylyqk}}</div>
                            <div v-else>—</div>
                        </div>
                    </th>
                    <th>
                        <div v-if="CurrYY2.by.xy === '同意' && !PJed">
                            <div v-if="CurrYY2.ItemStr.charAt(0)==='A'" @click="SelectI=1" class="XYC">
                                <div v-if="CurrYY2.by.b_zg==='初值'" class="AN ANGray ">点这里</div>
                                <div v-else class="AN ANGreen">{{CurrYY2.by.b_zg}}</div>
                            </div>
                            <div v-else></div>
                        </div>
                        <div v-else class="AN ANGray">
                            <div v-if="PJed && CurrYY2.ItemStr.charAt(0)==='A'">{{CurrYY2.by.b_zg==='初值'? '—':CurrYY2.by.b_zg}}</div>
                            <div v-else>—</div>
                        </div>
                    </th>
                    <th class="MR4">
                        <div v-if="CurrYY2.by.xy === '同意' && !PJed" @click="SelectI=3" class="XYC">
                            <div v-if="CurrYY2.by.b_ts==='初值'" class="AN ANGray">点这里</div>
                            <div v-else class="AN ANGreen TG">{{CurrYY2.by.b_ts}}</div>
                        </div>
                        <div v-else>
                            <div v-if="PJed">{{CurrYY2.by.b_ts==='初值'? '—':CurrYY2.by.b_ts}}</div>
                            <div v-else>—</div>
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    </div>
    <MySelect v-if="SelectI===1" :Options="['主约胜','被约胜','平局']" @FromMySelect="MySelect1"></MySelect>
    <MySelect v-if="SelectI===2" :Options="['主约迟到','主约爽约','主约准时']" @FromMySelect="MySelect2"></MySelect>
    <MySelect v-if="SelectI===3" :Options="['差评','一般','点赞']" @FromMySelect="MySelect3"></MySelect>
    <MySelect v-if="SelectI===0" :Options="['同意','拉黑','暂拒']" @FromMySelect="MySelect0"></MySelect>
</template>

<script>
    //需要考虑到：当本页正在编辑的同时，主约或别的被约发送了评价信息．因此，不能全文档存回!
    import {CurrByEndProcess} from '@/components/ListYY/CurrByEndProcess';
    import {GetItemStr3, GetItemType, myMongoDBPost, SendWXMsg} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MySelect from "@/components/SharedVues/MySelect";
    import MyInput from "@/components/SharedVues/MyInput";
    import FYMap from "../SharedVues/FYMap";
    import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";

    import MyToast from "../SharedVues/MyToast";
    import ShowPVT from "../MyPVTs/ShowPVT";

    export default {
        name: "BY3",
        components: {
            ShowPVT,
            MyToast,
            ShowUpdatePVT,
            FYMap,
            MyInput,
            MySelect,
            PopTitle
        },
        props: ['CurrYY'],
        data() {
            return {
                Show: -1,
                ShowMap: false,
                CardType: '',
                CardVal: 0,
                CurrVal: 0,

                ItemCode: '',
                ItemType: '',
                ItemName: '',

                FirstXYBool: 0,//首次响应标志
                Changed1: false,
                Changed2: false,
                Changed3: false,
                SelectI: -1,
                ShowInvitation: false,
                ShowZindex: this.$store.state.CurrZindex + 70,
                CurrYY2: {},
                PJed: false,//评价过标志
            }
        },
        mounted() {
            this.CurrYY2 = this.CurrYY;
            this.ItemCode = this.CurrYY2.ItemStr.split(':')[0];
            this.ItemType = GetItemType(this.ItemCode);
            this.ItemName = this.CurrYY2.ItemStr.split(':')[1];

            if (this.CurrYY2.by.xy === '同意' || this.CurrYY2.by.xy === '拉黑' || this.CurrYY2.by.xy === '暂拒') {
                this.FirstXYBool = 2;
                if (this.CurrYY2.by.b_ts !== '' && this.CurrYY2.by.b_ts !== '初值') this.PJed = true;//取其中一项来作为判断是否评价过的标志
            }//若已经响应过
            if (this.CurrYY2.ItemStr.split(':')[0].charAt(0) !== 'A') this.Changed1 = true;

            let Now = new Date().getTime(), meetStamp = new Date(this.CurrYY2.MeetTimeStr + ":00").getTime(); //时间字符串转为时间戳
            if (this.CurrYY2.by.xy === "未响应" && Now > meetStamp) {// 如果尚未响应,但已超过碰头时间,则应作结束处理
                this.CurrYY2.by.xy = "超时";
                this.BYMsgSave();
            }
        },

        methods: {
            MySelect0(Val) {
                this.CurrYY2.by.xy = Val;
                this.FirstXYBool = 1;
                this.CurrYY2.by.zg = '';
                this.SelectI = -1;
            },
            MySelect1(Val) {
                this.CurrYY2.by.b_zg = Val;
                this.Changed1 = true;
                this.SelectI = -1;
            },
            MySelect2(Val) {
                this.CurrYY2.by.b_zylyqk = Val;
                if (Val === '主约爽约') {
                    this.CurrYY2.by.b_zg = '主约爽约';
                    this.Changed1 = true;
                    this.CurrYY2.by.b_ts = '差评';
                    this.Changed3 = true;
                }
                this.Changed2 = true;
                this.SelectI = -1;
            },
            MySelect3(Val) {
                this.CurrYY2.by.b_ts = Val;
                this.Changed3 = true;
                this.SelectI = -1;
            },
            CallBack2(Val) {//历史信息
                if (Val.P) this.CurrYY2.by.Pic = Val.P;
                if (Val.V) this.CurrYY2.by.Video = Val.V;
                if (Val.T) this.CurrYY2.by.Text = Val.T;
            },
            FirstXY() {
                if (this.$store.state.UserName === '游客') return alert('游客操作无效! 请先注册、登录后，再进行该操作。');

                let that = this;
                myMongoDBPost("SaveBY/?ItemCode=" + this.ItemCode, {_id: that.CurrYY2._id, BY: that.CurrYY2.by}, function (data) {
                    let MyUrlPara = 'LandTo=ZY2QWERItemCode=' + that.ItemCode + 'QWERItemName=' + that.ItemName + 'QWERCurrYYId=' + that.CurrYY2._id + '___';
                    let MyUrlShow = '查看/评价';
                    let Msg = GetItemStr3(that.CurrYY2.ItemStr) + '☞被约『' + that.$store.state.UserName + '』同意了您的邀约。' + '[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;

                    if (that.CurrYY2.by.xy === '同意') {
                        let TemplateObj = {
                            TemplateIndex: 6,
                            Data: {
                                character_string2: {value: that.ItemCode + '>' + that.CurrYY2.MeetTimeStr},//订单编号
                                thing16: {value: that.$store.state.UserName},//客户名称
                                thing8: {value: '被约『' + that.$store.state.UserName + '』同意了您的邀约。'}//服务名称
                            }
                        }
                        SendWXMsg(that.CurrYY2.zyUserName, Msg, TemplateObj);
                    } else that.BYMsgSave();
                    alert('响应信息已发送!');
                    that.$emit('FromBY3');
                })
            },
            BYMsgSave() {
                if (this.$store.state.UserName === '游客') return alert('游客操作无效! 请先注册、登录后，再进行该操作。');
                let that = this;
                let ItemType = GetItemType(this.ItemCode);
                myMongoDBPost("SaveBY/?ItemCode=" + this.ItemCode, {_id: that.CurrYY2._id, BY: that.CurrYY2.by}, function (X) {
                    that.PJed = true;

                    if (X.EndBool) {//当前邀约结束时，应当向主约和每一个被约发送通知
                        let TJ = '';
                        if (that.CurrYY2.by.xy === '暂拒') TJ = '(被约' + that.CurrYY2.by._id + '因' + that.CurrYY2.by.zg + '暂拒了您)';
                        if (that.CurrYY2.by.xy === '拉黑') TJ = '(被约' + that.CurrYY2.by._id + '拉黑了您)';
                        if (that.CurrYY2.by.xy === '超时') TJ = '(被约' + that.CurrYY2.by._id + '在您约定的碰头时间之前，未能及时响应)';
                        if (TJ === '') TJ = "(战果：" + that.CurrYY2.by.zg + ")";

                        if (that.CurrYY2.by.AppearanceFee === 0) {
                            let TemplateObj = {
                                TemplateIndex: 2,
                                Data: {
                                    character_string8: {value: that.ItemCode + '>' + that.CurrYY2.MeetTimeStr},//订单编号
                                    thing11: {value: that.CurrYY2.ItemStr.split(":")[1]},//消费项目
                                    thing10: {value: that.CurrYY2.zyUserName + ":" + that.CurrYY2.by._id + "..."},//用户名称
                                    thing26: {value: '项目邀约活动已结束！'}//报单档口
                                }
                            };
                            let MyUrlSuffix = '\n[ MyUrlHere ]MyUrlHereMyUrl=' + 'LandTo=HistoryZYQWERItemCode=' + that.ItemCode + 'QWERCurrYYId=' + that.CurrYY2._id + '___' + 'MyUrl=查看';

                            SendWXMsg(that.CurrYY2.zyUserName, that.CurrYY2.ItemStr.split(":")[1] + '☞项目邀约活动已结束！' + TJ + MyUrlSuffix, TemplateObj);
                        }

                        let CurrYYDoc = X.YYDoc; //注意CurrYYDoc和that.CurrYY2不一样
                        for (let i1 = 0, len1 = CurrYYDoc.by.length; i1 < len1; i1++)  //遍历每一个被约
                            (function (i1) {
                                CurrByEndProcess(that, ItemType, that.ItemCode, that.ItemName, CurrYYDoc, CurrYYDoc.by[i1], 1);
                            })(i1);

                    } else {
                        let asd = '';
                        if (that.CurrYY2.ItemStr.charAt(0) === 'A' && that.CurrYY2.by.b_zg !== '' && that.CurrYY2.by.b_zg !== '初值') asd += '战果:' + that.CurrYY2.by.b_zg + "；";
                        if (that.CurrYY2.by.b_zylyqk !== '' && that.CurrYY2.by.b_zylyqk !== '初值') asd += '履约:' + that.CurrYY2.by.b_zylyqk + "。";
                        if (that.CurrYY2.by.b_ts !== '' && that.CurrYY2.by.b_ts !== '初值') asd += '互评:' + that.CurrYY2.by.b_ts + "；";
                        if (that.CurrYY2.by.xy !== '同意') asd = that.CurrYY2.by.xy + '了您的邀约。';
                        if (that.CurrYY2.by.xy === '暂拒') asd += "（" + that.CurrYY2.by.zg + ")";
                        let MyUrlPara = 'LandTo=ZY2QWERItemCode=' + that.ItemCode + 'QWERItemName=' + that.ItemName + 'QWERCurrYYId=' + that.CurrYY2._id + '___';
                        let MyUrlShow = '查看/评价';
                        let Msg = GetItemStr3(that.CurrYY2.ItemStr) + '☞被约『' + that.$store.state.UserName + '』已完成评价▷' + asd + '\n[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;

                        let TemplateObj = {
                            TemplateIndex: 6,
                            Data: {
                                character_string2: {value: that.ItemCode + '>' + that.CurrYY2.MeetTimeStr},//订单编号
                                thing16: {value: that.$store.state.UserName},//客户名称
                                thing8: {value: '被约已完成评价'}//服务名称
                            }
                        }
                        SendWXMsg(that.CurrYY2.zyUserName, Msg, TemplateObj);
                    }
                    alert('响应信息已发送!');
                    that.$emit('FromBY3');
                });
            },
        }
    }
</script>

<style scoped>

</style>
